
import { computed, defineComponent, onBeforeMount, watch } from 'vue'
import { StructuresNode } from 'types/interface/StructuresNode'
import { useStructures, useBreadcrumbs, useTenant } from '@/core'
import { useHead } from '@vueuse/head'
import { useI18n } from 'vue-i18n'
import useTranslations from '@/composables/useTranslations'
import TheHeading from '@/components/TheHeading.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import useAccessCheck from '@/composables/useAccessCheck'
import Card from '@/components/Card.vue'
import ButtonSources from '@/components/ButtonSources.vue'

export default defineComponent({
  name: 'Exercises',

  components: {
    TheHeading,
    Breadcrumbs,
    Card,
    ButtonSources,
  },

  setup() {
    const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs()
    const { getStructuresBySlugPath, getStructuresChildrens } = useStructures()
    const { gqlStructureQuery, gqlStructureChildrenQuery } = useTranslations()
    const { locale } = useI18n()
    const parentSlugPath = 'grip/oppgaver'
    const { showErudioProduct } = useAccessCheck()
    const parent = computed(() => getStructuresBySlugPath(parentSlugPath))
    const sections = computed(() =>
      getStructuresChildrens(parent.value.id, 'DIRECTORY')
        .filter((child) => showErudioProduct(child.id))
        .map((section) => {
          section.children = getStructuresChildrens(section.id, 'DIRECTORY')

          return section
        }),
    )
    const cardActions = (sectionSlug) => {
      return {
        type: 'tile',
        action: (child: StructuresNode) => {
          return `/oppgaver/${parentSlugPath}/${sectionSlug}/${child.slug}`
        },
      }
    }

    const { tenant } = useTenant()
    useHead({
      title: computed(() => `${parent.value.name} - ${tenant.value.name}`),
    })

    const loadContent = async () => {
      await gqlStructureQuery(parentSlugPath, locale.value, {})
      await gqlStructureChildrenQuery(parentSlugPath, locale.value, {})
      await Promise.all(
        sections.value.map(async (section) => {
          if (section.type === 'DIRECTORY') {
            await gqlStructureChildrenQuery(
              `${parentSlugPath}/${section.slug}`,
              locale.value,
              {},
            )
          }

          return section
        }),
      )
    }

    watch(locale, () => {
      loadContent()
    })

    onBeforeMount(async () => {
      clearBreadcrumbs()
      loadContent()
      setBreadcrumbs([{ name: parent.value.name }])
    })

    return {
      cardActions,
      parent,
      sections,
    }
  },
})
