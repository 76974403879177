import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-exercises" }
const _hoisted_2 = {
  key: 0,
  class: "v-exercises__sections"
}
const _hoisted_3 = { class: "v-exercises__section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheHeading = _resolveComponent("TheHeading")!
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ButtonSources = _resolveComponent("ButtonSources")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TheHeading, { level: "h1" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.parent.name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Breadcrumbs),
      (_ctx.sections && _ctx.sections.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sections, (section) => {
              return (_openBlock(), _createElementBlock("section", {
                key: section.id,
                class: "v-exercises__section-wrapper"
              }, [
                _createVNode(_component_TheHeading, { level: "h2" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(section.name), 1)
                  ]),
                  _: 2
                }, 1024),
                _createElementVNode("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.children, (sectionChild) => {
                    return (_openBlock(), _createBlock(_component_Card, {
                      key: sectionChild.id,
                      title: sectionChild.name,
                      description: sectionChild.description || ' ',
                      "image-id": sectionChild.cover,
                      item: sectionChild,
                      actions: _ctx.cardActions(section.slug),
                      class: "v-exercises__section-card",
                      disabled: !sectionChild.description
                    }, null, 8, ["title", "description", "image-id", "item", "actions", "disabled"]))
                  }), 128))
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ButtonSources)
  ], 64))
}